<template>
  <div class="card mb-4 border-0" style="width: 18rem">
    <img
      class="card-img-top rounded-circle"
      :src="profile.image"
      alt="Profile Image"
    />
    <div class="card-body text-center">
      <h5 class="card-title text-blue">{{ profile.name }}</h5>
      <p class="card-text text-secondary">{{ profile.position }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileCard",
  props: {
    profile: Object,
  },
};
</script>

<style scoped>
.card-img-top {
  max-height: 200px;
  min-height: 150px;
  object-fit: cover;
}
</style>
