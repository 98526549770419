<template>
  <div class="container">
    <h1 class="text-center text-blue">All Profiles</h1>
    <br /><br />
    <div class="row">
      <div
        v-for="(profile, index) in getProfiles"
        :key="index"
        class="col-md-4 col-sm-6"
      >
        <profile-card :profile="profile" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ProfileCard from "./ProfileCard.vue";

export default {
  name: "AllProfiles",
  computed: { ...mapGetters(["getProfiles"]) },
  components: { ProfileCard },
};
</script>
